/*
@contract error-page {
  background: BackgroundColor
  text: Color
  link-color: Color
}
*/

.errorPage {
  background: var(--error-page-background);
  color: var(--error-page-heading-color);
  padding-left: var(--space-6x);
  padding-right: var(--space-6x);
  padding-bottom: var(--space-10x);

  @media (--screen-md) {
    padding-left: var(--space-12x);
    padding-right: var(--space-12x);
  }
}

.errorPageSection {
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--screen-md) {
    flex-direction: row;
  }
}

.errorPageLink {
  font-size: var(--font-size-body);

  @media (--screen-md) {
    font-size: var(--font-size-md);
  }
}

.errorPageBottomSection {
  padding: 0;
}

.errorPageImage {
  width: 224px;
  margin: var(--space-10x) var(--space-9x);
  flex: none;

  @media (--screen-md) {
    margin-right: 80px;
    margin-bottom: var(--space-9x);
  }

  @media (--screen-lg) {
    margin-top: 58px;
    margin-bottom: 55px;
    margin-right: 104px;
    height: 426px;
    width: 368px;
  }
}

.errorPageImage img {
  object-fit: contain;
}

.errorPageHeaderSection {
  flex-grow: 1;

  @media (--screen-lg) {
    margin-top: 50px;
  }
}

.searchListingCardPreviewGroup {
  --horizontal-scroll-gradient-background-color: var(
    --error-page-listing-card-gradient
  );
}
.errorPageHeading {
  color: var(--error-page-heading-color);
  font-size: var(--font-size-3xl);
  margin-bottom: var(--space-2x);

  @media (--screen-md) {
    margin-top: 56px;
    margin-bottom: 29px;
    font-size: var(--font-size-6xl);
  }

  @media (--screen-lg) {
    margin-top: var(--space-9x);
  }

  @media (--screen-xl) {
    margin-top: 150px;
  }
}

.errorPageText {
  color: var(--error-page-text);
  font-weight: normal;
  margin-bottom: var(--space-8x);
}

.errorPagePopularCitiesHeading {
  border-top: 1px solid var(--color-gray-200);
  line-height: 1;
  color: var(--error-page-heading-color);
  padding-top: var(--space-8x);
  margin-bottom: var(--space-8x);
  font-size: var(--font-size-2xl);
}

.errorPageLinkSectionList {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.errorPageLinkSectionListNoHouses {
  grid-template-columns: 1fr 1fr;

  @media (--screen-md) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.searchInput {
  height: 62px;
}

.searchResults {
  position: absolute;
  transform: translateY(var(--space-2x));
  top: 72px;

  @media (--screen-md) {
    max-height: 55vh;
  }
}

.searchButton {
  padding: 0 var(--space-4x);
}

.searchResultHeader:first-child {
  border: 0;
}

.searchInputWrapper {
  border: var(--search-input-border-width) solid
    var(--search-input-border-color);
}

.section {
  --horizontal-scroll-buttons-container-top: calc(var(--space-12x) * -1);
  --horizontal-scroll-arrow-button-position: relative;
  --horizontal-scroll-arrow-button-direction: 0;
  --horizontal-scroll-container-padding: 0;
  --horizontal-scroll-list-padding: 0 0 var(--space-4x) 0;
}
